/* global Office */

import {ref} from "vue";

const conversationId = ref(null);
const subject = ref(null);
const body = ref(null);
const attachments = ref([]);
const email = ref(null);
const adresses = ref({});

export default function useEmail() {
    email.value = Office.context.mailbox.userProfile.emailAddress;
    conversationId.value = Office.context.mailbox.item.conversationId;
    subject.value = Office.context.mailbox.item.subject;
    adresses.value = {
        to: Office.context.mailbox.item.to,
        cc: Office.context.mailbox.item.cc,
        bcc: Office.context.mailbox.item.bcc,
        from: Office.context.mailbox.item.from,
    };
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, item => body.value = item.value);

    for (let attachmentData of Object.values(Office.context.mailbox.item.attachments)) {
        Office.context.mailbox.item.getAttachmentContentAsync(
            attachmentData.id,
            item => attachments.value.push({name: attachmentData.name, attachmentContent: item.value})
        );
    }

    const getEmailObject = () => {
        return {
            conversationId: conversationId.value,
            subject: subject.value,
            body: body.value,
            email: email.value,
            adresses: adresses.value,
        };
    };

    return {
        getEmailObject,
        conversationId,
        subject,
        body,
        attachments,
        email,
    };
}
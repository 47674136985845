/* global Office */

export default function useRoamingSettings() {
    const saveAsync = (result) => {
        if (result.status !== Office.AsyncResultStatus.Succeeded) {
            console.error(`Action failed with message ${result.error.message}`);
        } else {
            console.log(`Settings saved with status: ${result.status}`);
        }
    };

    const get = (key) => {
        return Office.context.roamingSettings.get(key);
    };
    const set = (key, value) => {
        Office.context.roamingSettings.set(key, value);
        Office.context.roamingSettings.saveAsync(saveAsync);
    };

    const remove = (key) => {
        Office.context.roamingSettings.remove(key);
        Office.context.roamingSettings.saveAsync(saveAsync);
    }

    return {get, set, remove};
}
<template>
    <div class="flex flex-col gap-3">
        <select v-model="environment">
            <option :value="null">(kies een omgeving)</option>
            <option v-for="(item, id) in environments" :key="id" :value="item">
                {{ item }}
            </option>
        </select>
        <input type="text" v-model="key">
        <small class="text-base text-red-500">{{ message }}</small>

        <button class="bg-[#9b323c] hover:bg-pink-900 transition-all text-white p-2.5 rounded-sm" @click="userLogin">Opslaan</button>
    </div>
</template>

<script setup>
import useUserSettings from "@/shared/useUserSettings";
import {ref} from "vue";

const {key, environment, environments, login} = useUserSettings();

const message = ref(null);

const userLogin = async () => {
    message.value = await login();
};
</script>
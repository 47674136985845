<template>
    <div class="flex flex-col p-2.5">
        <img alt="Dynamic logo" src="./images/logo-dynamic.png" id="logo" class="mb-4 w-full">
        <UserSettings v-if="savedKey === null || savedKey === undefined"/>
        <OrderSearch v-else/>
        <a href="#" class="mb-4 rounded-sm bg-[#565B5E] text-white transition-all p-2.5 hover:bg-zinc-700 absolute inset-x-0 bottom-0"
           v-if="savedKey !== null && savedKey !== undefined"
           @click.prevent="logout">Uitloggen</a>
    </div>
</template>

<script setup>
import OrderSearch from "@/components/OrderSearch.vue";
import UserSettings from "@/components/UserSettings.vue";
import useUserSettings from "@/shared/useUserSettings";
import useUser from "@/shared/useUser";

const {savedKey, logout} = useUserSettings();
const {getUser, user} = useUser();

if (savedKey.value !== null && savedKey.value !== undefined) {
    getUser().then(() => {
        if (user.value === null) {
            logout();
        }logout
    });
}

</script>

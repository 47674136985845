<template>
    <div>
        <div v-if="connectedOrders.length > 0">
            <strong>Gekoppelde orders</strong>
            <table class="mb-4 block w-full text-left">
                <tr v-for="connectedOrder in Object.values(connectedOrders)"
                    :key="connectedOrder.id"
                    class="inline-flex w-full justify-between border border-b-0 border-gray-300 py-2 first:rounded-t-sm last:rounded-b-sm last:border-b odd:bg-white">
                    <td class="px-2">
                        <small class="[word-break:break-word]">
                            <a :href="connectedOrder.route" target="_blank">{{ connectedOrder.number }}</a>
                        </small>
                    </td>
                    <td class="px-2">
                        <a class="bg-[#9b323c] hover:bg-pink-900 transition-all text-white p-1.5 text-xs rounded-sm" href="#" @click.prevent="disconnectFromOrder(connectedOrder.id)">
                            Ontkoppelen
                        </a>
                    </td>
                </tr>
          </table>
        </div>

        <strong>Koppelen aan order</strong>
        <div class="mb-4 flex border-b border-gray-300 pb-4">
            <input type="text" class="flex-1" v-model="searchTerm" @keyup.enter="getOrders">
            <button class="bg-[#9b323c] hover:bg-pink-900 transition-all text-white p-2.5 rounded-sm disabled:opacity-75 disabled:cursor-not-allowed"
                    :disabled="!searchTerm || searchTerm.length < 3"
                    @click="getOrders">Zoeken</button>
        </div>
        <strong>Zoekresultaten</strong>
        <table class="block w-full text-left">
              <tbody class="block w-full">
                  <tr v-for="order in orders"
                      :key="order.id"
                      class="inline-flex w-full justify-between border border-b-0 border-gray-300 py-2 first:rounded-t-sm last:rounded-b-sm last:border-b odd:bg-white">
                      <td class="px-2"><small class="[word-break:break-word]">{{ order.id }} - {{ order.number }}</small></td>
                      <td class="px-2">
                          <a class="bg-[#00b19d] transition-all text-white p-1.5 text-xs rounded-sm" href="#" @click.prevent="connectToOrder(order.id)" v-if="!orderConnected(order.id)">
                              Koppelen
                          </a>
                      </td>
                </tr>
                <tr v-if="!searchTerm">
                    <td class="px-2">Zoek eerst op order nummer.</td>
                </tr>
              </tbody>
          </table>
    </div>
</template>

<script setup>
import useOrder from "@/shared/useOrder";

const {
    searchTerm,
    getOrders,
    orders,
    orderConnected,
    connectToOrder,
    disconnectFromOrder,
    connectedOrders
} = useOrder();
</script>
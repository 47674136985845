import useRoamingSettings from "@/shared/useRoamingSettings";
import {ref} from "vue";
import axios from "axios";

const key = ref(null);
const environment = ref(null);
const environments = ref(null);
const savedKey = ref(null);
const savedEnvironment = ref(null);

export default function useUserSettings() {
    const {get, set, remove} = useRoamingSettings()

    environments.value = process.env.VUE_APP_DL_HOSTS.split(',');

    savedKey.value = get('key');
    savedEnvironment.value = get('environment');

    const login = async () => {

        try {
            const {data} = await axios.get(
                environment.value + '/api/users/me',
                {
                    headers: {
                        'Authorization': `Bearer ${key.value}`
                    }
                }
            );

            if (data.success) {
                set('key', key.value);
                set('environment', environment.value);
                savedKey.value = get('key');
                savedEnvironment.value = get('environment');
            }
        } catch (e) {
            return 'Login failed';
        }
    };

    const logout = () => {
        remove('key');
        remove('environment');
        savedKey.value = null;
        key.value = null;
        savedEnvironment.value = null;
        environment.value = null;
    };

    return {key, environment, environments, savedKey, savedEnvironment, login, logout};
}
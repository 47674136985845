import {ref} from "vue";
import axios from "axios";
import useUserSettings from "@/shared/useUserSettings";

const user = ref(null);
export default function useUser() {
    const {savedKey, savedEnvironment} = useUserSettings();

    const getUser = async () => {
        try {
            const {data} = await axios.get(
                savedEnvironment.value + '/api/users/me',
                {
                    headers: {
                        'Authorization': `Bearer ${savedKey.value}`
                    }
                }
            );
            user.value = data.user;
        } catch (e) {
            console.error(e);
        }
    };

    return {user, getUser};
}
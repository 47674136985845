import {ref} from "vue";
import axios from "axios";
import useEmail from "./useEmail";
import useUserSettings from "@/shared/useUserSettings";

const searchTerm = ref(null);
const orders = ref([]);
const connectedOrders = ref([]);

export default function useOrder() {
    const {conversationId, getEmailObject, attachments} = useEmail();
    const {savedKey, savedEnvironment} = useUserSettings();

    const getOrders = async () => {
        if (!searchTerm.value || !searchTerm.value.length > 3) {
            return;
        }
        const {data} = await axios.get(
            savedEnvironment.value + '/api/orders/get?filter=' + searchTerm.value,
            {
                headers: {
                    'Authorization': `Bearer ${savedKey.value}`
                }
            }
        );

        orders.value = data;
    };

    const getConnectedOrders = async () => {
        const {data} = await axios.get(
            savedEnvironment.value + '/api/orders/getConnectedToConversationId?conversation_id=' + encodeURIComponent(conversationId.value),
            {
                headers: {
                    'Authorization': `Bearer ${savedKey.value}`
                }
            }
        );
        connectedOrders.value = data;
    };
    getConnectedOrders();

    const connectToOrder = async (order_id) => {
        await axios.post(
            savedEnvironment.value + '/api/orders/connectToConversation/' + order_id,
            {
                email: getEmailObject(),
                attachments: attachments.value,
            },
            {
                headers: {
                    'Authorization': `Bearer ${savedKey.value}`
                }
            }
        );
        await getConnectedOrders();
    };

    const disconnectFromOrder = async (order_id) => {
        await axios.post(
            savedEnvironment.value + '/api/orders/disconnectFromConversation/' + order_id,
            {
                conversation_id: conversationId.value,
            },
            {
                headers: {
                    'Authorization': `Bearer ${savedKey.value}`
                }
            }
        );
        await getConnectedOrders();
    };

    const orderConnected = (order_id) => connectedOrders.value.some(order => order.id === order_id);

    return {
        getOrders,
        getConnectedOrders,
        orderConnected,
        connectToOrder,
        disconnectFromOrder,
        searchTerm,
        orders,
        connectedOrders,
    };
}